export default [
  {
    id: 'normal',
    title: 'Normálna'
  },
  {
    id: 'big',
    title: 'Veľká'
  }
]
