<script>
import NotifyService from '../../services/NotifyService'
import InfoboxNewView from './InfoboxNewView'

export default {
  name: 'InfoboxEditView',
  extends: InfoboxNewView,
  methods: {
    getInfobox () {
      this.$store.dispatch('infobox/fetchOne', this.$route.params.id)
        .then(() => {
          this.infobox = this.$store.getters['infobox/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.infobox.image = null
      if (this.$store.getters['infobox/infoboxImage']) {
        this.infobox.image = this.$store.getters['infobox/infoboxImage'].id
      }
      this.$store.dispatch('infobox/update', this.infobox)
        .then(() => {
          this.infobox = this.$store.getters['infobox/detail']
          if (this.$store.getters['infobox/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['infobox/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getInfobox()
  }
}
</script>
