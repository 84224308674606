<template>
  <InfoboxEditView disabled />
</template>

<script>
import InfoboxEditView from '@/views/infobox/InfoboxEditView'

export default {
  name: 'InfoboxView',
  components: {
    InfoboxEditView
  }
}
</script>
